import { GlobeAltIcon } from "@heroicons/react/solid"
import { Link } from "gatsby"
import React from "react"
import Container from "../components/Container"
import Hero from "../components/Hero"
import HeroTitle from "../components/HeroTitle"
import Layout from "../components/Layout"
import SEO from "../components/SEO/SEO"
import links from "../content/links"
import metaImg from "../images/luottokorttipuhelin.svg"

export default (): JSX.Element => {
  return (
    <Layout>
      <SEO
        title="Palvelut"
        description="Web-ohjelmistot, mobiilisovellukset, kotisivut, käyttöliittymäsuunnittelu, Iot"
        pageImage={metaImg}
        pageUrl={links.services}
      />

      <section id="hero">
        <Hero>
          <HeroTitle>
            <h1>
              <span className="block">Meidän</span>
              <span className="block text-indigo-400">palvelut</span>
            </h1>
          </HeroTitle>
        </Hero>
      </section>

      <section>
        <Container>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
            <div className="md:mr-5 mt-10">
              <div className="flex items-center justify-center h-12 w-12 rounded-md mr-5 bg-indigo-500 text-white float-left">
                <GlobeAltIcon className="h-6 w-6" aria-hidden="true" />
              </div>
              <Link
                to={links.website}
                className="mt-5 text-lg leading-6 font-medium text-indigo-600 block"
              >
                Kotisivut
              </Link>
              <div className="mt-2 text-base text-gray-500">
                Teemme yritykselle kotisivut nopeasti ja laadukkaasti. Meiltä
                saat myös sivuillesi hakukoneoptimoinnit, digitaalisen...
                <Link className="text-indigo-600 block" to={links.website}>
                  Lue lisää
                </Link>
              </div>
            </div>

            <div className="md:mr-5 mt-10">
              <div className="flex items-center justify-center h-12 w-12 rounded-md mr-5 bg-indigo-500 text-white float-left">
                <GlobeAltIcon className="h-6 w-6" aria-hidden="true" />
              </div>
              <Link
                to={links.iot}
                className="mt-5 text-lg leading-6 font-medium text-indigo-600 block"
              >
                Internet of things
              </Link>
              <div className="mt-2 text-base text-gray-500">
                Esineiden internet mahdollistaa fyysisen laitteesi kytkennän
                verkkoon. Mahdollistamme esimerkiksi laitteen tuottaman...
                <Link className="text-indigo-600 block" to={links.iot}>
                  Lue lisää
                </Link>
              </div>
            </div>

            <div className="md:mr-5 mt-10">
              <div className="flex items-center justify-center h-12 w-12 rounded-md mr-5 bg-indigo-500 text-white float-left">
                <GlobeAltIcon className="h-6 w-6" aria-hidden="true" />
              </div>
              <Link
                to={links.webDev}
                className="mt-5 text-lg leading-6 font-medium text-indigo-600 block"
              >
                Web-kehitys
              </Link>
              <div className="mt-2 text-base text-gray-500">
                Meiltä löytyy vahva tekninen osaaminen web-pohjaisten
                sovellusten kehittämiseen koko projektin elinkaaren aikana...
                <Link className="text-indigo-600 block" to={links.webDev}>
                  Lue lisää
                </Link>
              </div>
            </div>

            <div className="md:mr-5 mt-10">
              <div className="flex items-center justify-center h-12 w-12 rounded-md mr-5 bg-indigo-500 text-white float-left">
                <GlobeAltIcon className="h-6 w-6" aria-hidden="true" />
              </div>
              <Link
                to={links.softwareSolutions}
                className="mt-5 text-lg leading-6 font-medium text-indigo-600 block"
              >
                Ohjelmistokehitys ratkaisut
              </Link>
              <div className="mt-2 text-base text-gray-500">
                Teemme ja suunnittelemme tietojärjestelmiä, eli ohjelmistoja.
                Ohjelmistoja pystytään ohjaamaan esimerkiksi tietokoneiden...
                <Link
                  className="text-indigo-600 block"
                  to={links.softwareSolutions}
                >
                  Lue lisää
                </Link>
              </div>
            </div>

            <div className="md:mr-5 mt-10">
              <div className="flex items-center justify-center h-12 w-12 rounded-md mr-5 bg-indigo-500 text-white float-left">
                <GlobeAltIcon className="h-6 w-6" aria-hidden="true" />
              </div>
              <Link
                to={links.uiDesign}
                className="md:mt-5 text-lg leading-6 font-medium text-indigo-600 block"
              >
                Käyttöliittymäsuunnittelu
              </Link>
              <div className="mt-2 text-base text-gray-500">
                Käyttöliittymäsuunnittelu (UI) ja käyttäjäkokemussuunnittelu (UX
                Design) on iso osa tietokoneiden, mobiililaitteiden...
                <Link className="text-indigo-600 block" to={links.uiDesign}>
                  Lue lisää
                </Link>
              </div>
            </div>

            <div className="md:mr-5 mt-10">
              <div className="flex items-center justify-center h-12 w-12 rounded-md mr-5 bg-indigo-500 text-white float-left">
                <GlobeAltIcon className="h-6 w-6" aria-hidden="true" />
              </div>
              <Link
                to={links.mobile}
                className="md:mt-5 text-lg leading-6 font-medium text-indigo-600 block"
              >
                Mobiilikehitys
              </Link>
              <div className="mt-2 text-base text-gray-500">
                Viime vuosina mobiilikehitys on muuttunut ja kehittynyt
                mobiililaitteiden käytön yleistyessä. Tällä hetkellä
                ohjelmistoja voidaan...
                <Link className="text-indigo-600 block" to={links.mobile}>
                  Lue lisää
                </Link>
              </div>
            </div>

            <div className="md:mr-5 mt-10">
              <div className="flex items-center justify-center h-12 w-12 rounded-md mr-5 bg-indigo-500 text-white float-left">
                <GlobeAltIcon className="h-6 w-6" aria-hidden="true" />
              </div>
              <Link
                to={links.software}
                className="md:mt-5 text-lg leading-6 font-medium text-indigo-600 block"
              >
                Ohjelmistokehitys
              </Link>
              <div className="mt-2 text-base text-gray-500">
                Ohjelmistokehitys on tarjoamiemme palveluiden kulmakivi.
                Yrityksemme ammattitaito perustuu johtaviin...
                <Link className="text-indigo-600 block" to={links.software}>
                  Lue lisää
                </Link>
              </div>
            </div>

            <div className="md:mr-5 mt-10">
              <div className="flex items-center justify-center h-12 w-12 rounded-md mr-5 bg-indigo-500 text-white float-left">
                <GlobeAltIcon className="h-6 w-6" aria-hidden="true" />
              </div>
              <Link
                to={links.innovaatioSeteli}
                className="md:mt-5 text-lg leading-6 font-medium text-indigo-600 block"
              >
                Innovaatioseteli
              </Link>
              <div className="mt-2 text-base text-gray-500">
                Tuotamme projekteja myös innovaatioseteleiden avulla.
                Innovaatioseteli on tarkoitettu pienille ja keskisuurille
                yrityksille...
                <Link
                  className="text-indigo-600 block"
                  to={links.innovaatioSeteli}
                >
                  Lue lisää
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </section>
    </Layout>
  )
}
